import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/css/styles.css';
// color picker
// import sketch from "vue-color";

Vue.config.productionTip = false;

// Globally register your component
// Vue.component('color-picker', sketch);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
