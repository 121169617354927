<template>
  <div
    class="collection__list no__scrollbar"
    :class="{ d__block: solidIcons.length === 0 }"
  >
    <no-result :searched-value="searchedValue" v-if="solidIcons.length === 0" />
    <div
      v-else
      class="collection__list--item"
      v-for="(icon, i) in solidIcons"
      :key="i"
    >
      <button
        :class="[
          'flex__c icon__container solid',
          { 'icon__container--selected': isSelected(icon), premium: isPremium },
        ]"
        @click="clickIcon(icon)"
        :data-attr="icon.name"
        :data-premium="showPremium(icon)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :width="iconSize"
          :height="iconSize"
          fill="none"
          viewBox="0 0 24 24"
          v-html="icon.code"
        ></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { isArray } from 'lodash-es';
import noResult from './nosearch.vue';

export default {
  components: { noResult },
  props: {
    listIcons: {
      type: Object,
      required: true,
    },
    iconSize: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
    searchedValue: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      edgeSelected: 'sharp',
    };
  },
  computed: {
    solidIcons() {
      const { icons } = this.listIcons;
      if (this.edgeSelected === 'sharp') {
        let filteredIcons = [];
        filteredIcons = icons.filter((icon) => {
          return icon.edges === 'sharp';
        });
        if (this.searchedValue) {
          filteredIcons = this.searchIcon(filteredIcons);
        }
        return filteredIcons;
      }
      let filteredIcons = [];
      filteredIcons = icons.filter((icon) => {
        return icon.edges === 'curve';
      });
      if (this.searchedValue) {
        filteredIcons = this.searchIcon(filteredIcons);
      }
      return filteredIcons;
    },
    showPremium() {
      return (icon) => (this.isPremium ? null : icon.premium);
    },
    isSelected() {
      return (icon) =>
        !!this.selected.find(
          ({ name, type }) => name === icon.name && type === 'solid'
        );
    },
  },
  methods: {
    searchIcon(filteredIcons) {
      return filteredIcons.filter((item) => {
        if (!isArray(item.tags)) return false;
        return item.tags.some((substring) =>
          substring
            .toLowerCase()
            .includes(String(this.searchedValue).toLowerCase())
        );
      });
    },
    filterSharpIcon() {
      this.edgeSelected = 'sharp';
    },
    filterCurveIcon() {
      this.edgeSelected = 'curve';
    },
    clickIcon(icon) {
      const iconData = {
        name: icon.name,
        type: 'solid',
        isPremium: icon.premium && icon.premium === 'premium',
      };

      this.$emit(
        this.isSelected({ name: icon.name }) ? 'deselect-icon' : 'select-icon',
        iconData
      );
    },
  },
};
</script>
