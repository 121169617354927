<template>
  <div
    class="collection__list no__scrollbar"
    :class="{ d__block: outlineIcons.length === 0 }"
  >
    <no-result
      :searched-value="searchedValue"
      v-if="outlineIcons.length === 0"
    />
    <div
      v-else
      class="collection__list--item"
      v-for="(icon, i) in outlineIcons"
      :key="i"
    >
      <button
        :class="[
          'flex__c icon__container outline',
          { 'icon__container--selected': isSelected(icon), premium: isPremium },
        ]"
        :aria-label="icon.name"
        @click="clickIcon(icon)"
        :data-attr="icon.name"
        :data-premium="showPremium(icon)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :width="iconSize"
          :height="iconSize"
          :stroke-width="(24 / iconSize) * strokeWidth"
          :stroke="strokeColor"
          fill="none"
          :stroke-linejoin="lineJoin"
          :stroke-linecap="lineCap"
          viewBox="0 0 24 24"
          v-html="icon.code"
        ></svg>
      </button>
    </div>
  </div>
</template>

<script>
import { isArray } from 'lodash-es';
import noResult from './nosearch.vue';

export default {
  components: { noResult },
  props: {
    listIcons: {
      type: Object,
      required: true,
    },
    iconSize: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
    strokeWidth: {
      type: String,
      required: true,
    },
    lineJoin: {
      type: String,
      required: true,
    },
    lineCap: {
      type: String,
      required: true,
    },
    searchedValue: {
      type: String,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
    isPremium: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    outlineIcons() {
      const { icons } = this.listIcons;
      if (this.searchedValue) {
        return icons.filter((item) => {
          if (!isArray(item.tags)) return false;
          return item.tags.some((substring) =>
            substring
              .toLowerCase()
              .includes(String(this.searchedValue).toLowerCase())
          );
        });
      }
      return icons;
    },
    showPremium() {
      return (icon) => (this.isPremium ? null : icon.premium);
    },
    isSelected() {
      return (icon) =>
        !!this.selected.find(
          ({ name, type }) => name === icon.name && type === 'outline'
        );
    },
  },
  methods: {
    clickIcon(icon) {
      const iconData = {
        name: icon.name,
        type: 'outline',
        isPremium: icon.premium && icon.premium === 'premium',
      };

      const eventName = this.isSelected({ name: icon.name })
        ? 'deselect-icon'
        : 'select-icon';
      this.$emit(eventName, iconData);
    },
  },
};
</script>
