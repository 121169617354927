<template>
  <div class="nosearch">
    <div>
      <svg width="48" height="48" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <rect width="24" height="24" />
          <path
            class="fill__w"
            stroke=""
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,18 C7,15.2385763 9.23857625,13 12,13 C14.7614237,13 17,15.2385763 17,18 L17,18 M7,9.5 C7,8.67157288 7.67157288,8 8.5,8 C9.32842712,8 10,8.67157288 10,9.5 M14,9.5 C14,8.67157288 14.6715729,8 15.5,8 C16.3284271,8 17,8.67157288 17,9.5"
          />
        </g>
      </svg>
    </div>
    <p>
      Sorry! there are no icons for <span>"{{ searchedValue }}"</span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    searchedValue: {
      type: String,
      required: true,
    },
  },
};
</script>

<style></style>
