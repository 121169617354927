<template>
  <div class="wrapper">
    <div class="maincontent">
      <header class="header flex__sb">
        <div class="logo__header flex__sb">
          <a href="/" class="logo flex__c" aria-label="Vzy Icons Logo">
            <svg
              aria-label="Vzy Icons Logo"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <polygon
                fill-rule="evenodd"
                points="16 2 12.001 10 12.001 5.001 8.5 5.001 10.001 2 2 2 12 22 22 2"
                transform="translate(-2 -2)"
              />
            </svg>
          </a>
          <div class="flex__sb ac">
            <p>
              <a href="https://vzy.co/?ref=vzyicons" target="_blank">vzy.co↗</a>
            </p>
            <div aria-label="social share" class="socialShare flex__sa">
              <a
                href="https://twitter.com/intent/tweet?text=Customize+1000%2B+SVG+icons+in+one+click+%E2%86%92+icons.vzy.co+via+%40evansakanno"
                aria-label="Share on Twitter"
                target="_blank"
                rel="noopener"
              >
                <svg
                  aria-label="Twitter Icon"
                  class="socialShare__item"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <rect width="24" height="24" />
                    <path
                      class="fill__w"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M22,4.00932808 C21.1294409,4.62329958 20.1655526,5.09288927 19.1454545,5.40001323 C18.0239632,4.11070975 16.2167,3.65857939 14.6200741,4.26788048 C13.0234481,4.87718156 11.9770912,6.41830459 12,8.12684684 L12,9.03579138 C8.75514984,9.11991638 5.68365937,7.57418176 3.81818182,4.91827262 C3.81818182,4.91827262 0.181818182,13.0987735 8.36363636,16.7345516 C6.49138869,18.0052255 4.26105174,18.642362 2,18.5524407 C10.1818182,23.0971634 20.1818182,18.5524407 20.1818182,8.09957851 C20.180981,7.84639531 20.1566341,7.59383668 20.1090909,7.34515454 C21.036912,6.43029557 21.6916613,5.27522676 22,4.00932808 Z"
                    />
                  </g>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https://icons.vzy.co"
                aria-label="Share on Facebook"
                target="_blank"
                rel="noopener"
              >
                <svg
                  aria-label="Facebook Icon"
                  class="socialShare__item"
                  viewBox="0 0 24 24"
                >
                  <g fill="none" fill-rule="evenodd">
                    <rect width="24" height="24" />
                    <path
                      class="fill__w"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M16,8 L14.5,8 C13.1192881,8 12,9.11928813 12,10.5 L12,22 L12,22 M15,12.9489451 L10,12.9489451"
                    />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </header>
      <main class="main flex">
        <div class="content">
          <section class="top">
            <h1>Vzy Icons</h1>
            <p class="top__ds">
              <span> 1000+ SVG icons for unlimited projects.</span>
              <span> Customise and download in outline and solid styles.</span>
            </p>
            <p class="top__mb">
              1000+ SVG icons for all your projects. <br />Customise and
              download on your desktop.
            </p>
          </section>
          <section class="middle grid">
            <div class="search">
              <svg
                aria-label="Search Icon"
                class="search__view"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  fill-rule="evenodd"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9.5 17a7.5 7.5 0 1 0 0-15 
                          7.5 7.5 0 0 0 0 15zm12.007 4.507l-6.694-6.694"
                />
              </svg>
              <input
                v-model="searchedValue"
                class="search__input"
                type="text"
                role="searchbox"
                autocomplete="on"
                aria-label="Search all icons"
                placeholder='Search by keywords like "chat" or "arrow"'
              />
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                class="search__close"
                aria-label="reset search field"
                @click="searchedValue = ''"
              >
                <g fill="none" fill-rule="evenodd">
                  <rect width="24" height="24" />
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M18,6 L6,18 M6,6 L18,18"
                  />
                </g>
              </svg>
            </div>
            <nav class="type__nav flex__sa">
              <button
                v-for="tab in tabs"
                :key="tab"
                :class="['nav', { active: isActiveTab(tab) }]"
                @click="showTab(tab)"
              >
                {{ tab }}
              </button>
              <!-- <button class="nav"> Duotone </button> -->
            </nav>
          </section>
          <section v-show="!isDownloading" class="icons no__scrollbar">
            <component
              :is="selected"
              :ref="selected"
              :list-icons="listIcons"
              :searched-value="searchedValue"
              class="collection"
              :line-join="lineJoin"
              :line-cap="lineCap"
              :icon-size="iconSize"
              :stroke-width="strokeWidth"
              :stroke-color="strokeColor"
              :is-premium="isPremium"
              :selected="icons"
              @deselect-icon="handleIconDeselect"
              @select-icon="handleIconSelect"
            />
          </section>
          <section class="bottom--main">
            <p>
              <span>Think I’m missing an icon?</span>
              <a
                href="https://twitter.com/share?text=Hey%20%40evansakanno%20please%20add%20(icon%20name)%20to%20"
                target="_blank"
              >
                Tweet me →
              </a>
            </p>
          </section>
          <div class="motab">
            <div class="motab__top flex">
              <section class="pro__code">
                <p>
                  New Year Deal
                  <span class="small">Save $10 on lifetime access</span>
                </p>
                <button class="btn--fixed btn--access" @click="handlePay">
                  Buy Now $<span>{{ price }}</span>
                </button>
              </section>
              <section class="pro__details">
                <p>
                  Purchase and get lifetime access to my growing library of
                  custom SVG icons for unlimited projects.
                </p>
                <ul class="listings">
                  <li>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 7L9 18L4 13"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    1000+ SVG Icons
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 7L9 18L4 13"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Full app access
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 7L9 18L4 13"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Sketch and Figma Ready
                  </li>
                  <li>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 7L9 18L4 13"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Free future updates
                  </li>
                </ul>
              </section>
            </div>
            <section>
              <div class="motab__bottom flex__sb">
                <p class="flex__c">
                  Vzy -
                  <a
                    href="https://twitter.com/@evansakanno"
                    style="display: inline-block; margin-left: 5px"
                    target="_blank"
                  >
                    @evansakanno</a
                  >
                </p>
                <div class="flex__c ac">
                  <button
                    class="header__btn btn__scheme"
                    aria-label="Theme Mode Toggle"
                    @click="toggleTheme"
                  >
                    <svg
                      aria-label="Light mode"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      class="sun"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <rect width="24" height="24" />
                        <path
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12,16.5 C14.4852814,16.5 16.5,14.4852814 16.5,12 C16.5,9.51471863 14.4852814,7.5 12,7.5 C9.51471863,7.5 7.5,9.51471863 7.5,12 C7.5,14.4852814 9.51471863,16.5 12,16.5 Z M12,2 L12,4.66666667 M12,19.3333333 L12,22 M22,12 L19.3333333,12 M4.66666667,12 L2,12 M4.93333333,4.93333333 L6.8,6.8 M17.2,17.2 L19.0666667,19.0666667 M19.0666667,4.93333333 L17.2,6.8 M6.8,17.2 L4.93333333,19.0666667"
                        />
                      </g>
                    </svg>
                    <svg
                      aria-label="Dark mode"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      class="moon"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <rect width="24" height="24" />
                        <path
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12.7938533,4 C14.1371822,6.54211004 13.7098609,9.65776368 11.7316963,11.7443209 C9.75353168,13.830878 6.66508032,14.4236421 4.05498985,13.2177053 C4.69798975,17.3316447 8.38215596,20.2693718 12.5360028,19.9804027 C16.6898496,19.6914337 19.9317615,16.2718828 19.9989388,12.1085387 C20.0661161,7.94519466 16.9362149,4.42283187 12.7938533,4 L12.7938533,4 Z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      <footer>
        <div class="flex__sb">
          <p class="flex__c">
            Vzy -
            <a
              href="https://twitter.com/@evansakanno"
              style="display: inline-block; margin-left: 5px"
              target="_blank"
            >
              @evansakanno</a
            >
          </p>
          <div class="flex__sb ac">
            <button
              class="header__btn btn__scheme"
              aria-label="Theme Mode Toggle"
              @click="toggleTheme"
            >
              <svg
                aria-label="Light mode"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                class="sun"
              >
                <g fill="none" fill-rule="evenodd">
                  <rect width="24" height="24" />
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12,16.5 C14.4852814,16.5 16.5,14.4852814 16.5,12 C16.5,9.51471863 14.4852814,7.5 12,7.5 C9.51471863,7.5 7.5,9.51471863 7.5,12 C7.5,14.4852814 9.51471863,16.5 12,16.5 Z M12,2 L12,4.66666667 M12,19.3333333 L12,22 M22,12 L19.3333333,12 M4.66666667,12 L2,12 M4.93333333,4.93333333 L6.8,6.8 M17.2,17.2 L19.0666667,19.0666667 M19.0666667,4.93333333 L17.2,6.8 M6.8,17.2 L4.93333333,19.0666667"
                  />
                </g>
              </svg>
              <svg
                aria-label="Dark mode"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                class="moon"
              >
                <g fill="none" fill-rule="evenodd">
                  <rect width="24" height="24" />
                  <path
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12.7938533,4 C14.1371822,6.54211004 13.7098609,9.65776368 11.7316963,11.7443209 C9.75353168,13.830878 6.66508032,14.4236421 4.05498985,13.2177053 C4.69798975,17.3316447 8.38215596,20.2693718 12.5360028,19.9804027 C16.6898496,19.6914337 19.9317615,16.2718828 19.9989388,12.1085387 C20.0661161,7.94519466 16.9362149,4.42283187 12.7938533,4 L12.7938533,4 Z"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </footer>
      <!-- cookie -->
      <div v-if="!hideCookiesMsg" class="fixed">
        <div class="cookie flex">
          <p class="">
            This site uses <a href="https://vzy.co/legal">cookies</a> to improve
            your experience.
          </p>
          <button class="btn--cookie" @click="hideCookie">Okay, Sure!</button>
        </div>
      </div>
    </div>
    <aside class="sidebar">
      <header class="sidebar__header flex">
        <p class="customise">Customise</p>
        <div class="checkout flex__sb">
          <p>Get all icons at $29</p>
          <button
            v-if="hasCloseAction"
            aria-label="Close Pro page"
            class="btn__back"
            @click="handleCloseAction"
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <rect width="24" height="24" />
                <path
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18,6 L6,18 M6,6 L18,18"
                />
              </g>
            </svg>
          </button>
        </div>
        <div class="license-check flex__sb">
          <p>License Check</p>
          <button
            v-if="hasCloseAction"
            aria-label="Close Pro page"
            class="btn__back"
            @click="handleCloseAction"
          >
            <svg width="24" height="24" viewBox="0 0 24 24">
              <g fill="none" fill-rule="evenodd">
                <rect width="24" height="24" />
                <path
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18,6 L6,18 M6,6 L18,18"
                />
              </g>
            </svg>
          </button>
        </div>
      </header>
      <div class="sidebar__content">
        <div class="control">
          <section class="">
            <div class="cal__wrap flex__sb">
              <p>Size<sup>(px)</sup></p>
              <div class="icon__cal">
                <button
                  aria-label="Decrease icon size by 8px"
                  class="control__item control__item--button"
                  @click="changeIconSize('decrease')"
                >
                  <svg
                    aria-label="Minus icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="24" height="24" />
                      <line
                        x1="5"
                        x2="19"
                        y1="12"
                        y2="12"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </button>
                <input
                  id=""
                  v-model="iconSize"
                  type="number"
                  class="control__item control__item--input"
                  name="iconSize"
                  aria-label="Icon size in px"
                  value="24"
                  step="8"
                  min="16"
                  max="64"
                />
                <button
                  aria-label="Increase icon size by 8px"
                  class="control__item control__item--button"
                  @click="changeIconSize('increase')"
                >
                  <svg
                    aria-label="Plus icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="24" height="24" />
                      <path
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12,5 L12,19 M5,12 L19,12"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="isActiveTab('outline')" class="cal__wrap flex__sb">
              <p>Width<sup>(px)</sup></p>
              <div class="icon__cal">
                <button
                  aria-label="Decrease icon width 0.5px"
                  class="control__item control__item--button"
                  @click="changeIconWidth('decrease')"
                >
                  <svg
                    aria-label="Minus icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="24" height="24" />
                      <line
                        x1="5"
                        x2="19"
                        y1="12"
                        y2="12"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </button>
                <input
                  id=""
                  v-model="strokeWidth"
                  type="number"
                  class="control__item control__item--input"
                  name="strokeWidth"
                  aria-label="Stroke Width in px"
                  value="1"
                  step="0.5"
                  min="1"
                  max="2"
                />
                <button
                  :disabled="iconSize === '16' && strokeWidth >= '1.5'"
                  aria-label="Increase icon width by 0.5px"
                  class="control__item control__item--button"
                  @click="changeIconWidth('increase')"
                >
                  <svg
                    aria-label="Plus icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <rect width="24" height="24" />
                      <path
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12,5 L12,19 M5,12 L19,12"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div class="cal__wrap flex__sb">
              <p>Color</p>
              <div ref="colorpicker" class="color__input flex">
                <div class="hex flex__c">#</div>
                <input
                  v-model="colorValue"
                  class="color__icon"
                  type="text"
                  value=""
                  name="iconsColor"
                  aria-label="Icon color change' color given in any valid format"
                  maxlength="8"
                  @keyup="colorChange"
                />
                <!-- v-model="strokeColor" removed the v-model because the fucntion already updates it but added a value that just reflects -->
                <div class="color__swatch flex__c" @click="showPicker">
                  <div class="color__swatch--item" />
                </div>
                <color-picker
                  v-if="displayPicker"
                  :value="color"
                  @input="updateValue"
                />
              </div>
            </div>
            <div class="cal__wrap flex__sb">
              <p>Edges</p>
              <div class="flex corners__but">
                <button
                  :class="{ active: lineJoin == 'arcs' }"
                  @click="sharpIcon"
                >
                  <svg
                    aria-label="sharp edge con"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 21V1H21" stroke="black" />
                  </svg>
                </button>
                <button
                  :class="{ active: lineJoin == 'round' }"
                  @click="curveIcon"
                >
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path
                      d="M0.641479 21V11C0.641479 5.47715 5.11863 1 10.6415 1H20.6415"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="download">
              <button
                class="btn"
                :disabled="icons.length == 0 || isDownloading"
                @click="downloadIcons(selected)"
              >
                <span> {{ downloadText }} </span>
              </button>
            </div>
          </section>
          <section>
            <div v-if="!isPremium" class="bottom">
              <button class="btn--access" @click="showPro">
                Get Pro Access
              </button>
              <button
                class="btn--border border--light"
                @click="showLicenseCheck"
              >
                Already Paid
              </button>
            </div>
          </section>
        </div>
        <div class="pro flex">
          <section class="pro__details">
            <p>
              Purchase and get lifetime access to my growing library of custom
              SVG icons for unlimited projects.
            </p>
            <ul class="listings">
              <li>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 7L9 18L4 13"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                1000+ SVG Icons
              </li>
              <li>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 7L9 18L4 13"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Full app access
              </li>
              <li>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 7L9 18L4 13"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Sketch and Figma Ready
              </li>
              <li>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 7L9 18L4 13"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Free future updates
              </li>
            </ul>
          </section>
          <section class="pro__code">
            <p style="text-align: center">
              Lifetime Access
              <span class="small">for unlimited paid projects</span>
            </p>
            <div class="license__but" />
            <button class="btn--access" @click="handlePay">
              Buy Now $<span>{{ price }}</span>
            </button>
            <button class="btn--border border--light" @click="showLicenseCheck">
              Already Paid
            </button>
          </section>
        </div>
        <div class="license__input-wrap">
          <section class="license__input">
            <div>
              <label for="licensekey">
                Enter the license key sent to your email after you purchased Vzy
                Icons Pro on Gumroad.
              </label>
              <input
                v-model="licenseKey"
                type="text"
                name="licensekey"
                class=""
                placeholder="e.g: 342453D-758636R"
                @keydown="removeError"
              />
              <button class="btn mt btn-license" @click="checkGumRoadLicense">
                {{ butState }}
              </button>
              <p class="error">
                {{ hasLicenseError ? licenseErrorMessage : ' ' }}
              </p>
            </div>
            <div>
              <button class="alp" @click="handlePay">
                Buy License $<span>{{ price }}</span>
              </button>
            </div>
          </section>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Outline from '@/components/outlineicons.vue';
import Solid from '@/components/solidicons.vue';
// import qs from 'qs';
// import axios from 'axios';

import { gsap } from 'gsap';
import { Chrome } from 'vue-color';
import * as Cookies from 'js-cookie';
import axios from 'axios';
import { forAwaitEach } from 'iterall';
import solidIcons from '../solid.json';
import outlineIcons from '../outline.json';

export default {
  name: 'Home',
  components: {
    Outline,
    Solid,
    'color-picker': Chrome,
  },
  data() {
    return {
      tabs: ['Outline', 'Solid'],
      selected: 'Outline',
      lineJoin: 'arcs',
      lineCap: 'square',
      iconSize: '24',
      strokeWidth: '1',
      strokeColor: '#808080',
      colorValue: '808080',
      windowWidth: window.innerWidth,
      color: '#000000',
      displayPicker: false,
      icons: [],
      price: '29',
      searchedValue: '',
      isPremium: false,
      licenseKey: '',
      licenseErrorMessage: '',
      isDownloading: false,
      butState: 'Activate',
      currentSideBarState: 'showing-editor',
      customDownloadText: '',
    };
  },
  computed: {
    listIcons() {
      if (this.isActiveTab('outline')) {
        return outlineIcons;
      }
      return solidIcons;
    },
    hideCookiesMsg() {
      return Cookies.get('show-cookies-msg');
    },
    isActiveTab() {
      return (tab) => this.selected.toLowerCase() === tab.toLowerCase();
    },
    isActiveEdge() {
      return this.lineJoin;
    },
    hasPremiumIcons() {
      return !!this.icons.find((icon) => icon.isPremium);
    },
    downloadText() {
      if (this.customDownloadText) return this.customDownloadText;
      if (this.icons.length === 0) {
        return 'No selected icon(s)';
      }
      if (this.icons.length === 1) return 'Download 1 Icon';
      if (this.isDownloading) return `Downloading ${this.icons.length} Icons`;
      return `Download ${this.icons.length} Icons`;
    },
    // getActionTitle() {
    //   if (this.isDownloading)
    //     return `Downloading selected icon${this.icons.length > 1 ? "s" : ""}`;
    //   return "Customise";
    // },
    hasLicenseError() {
      return this.licenseErrorMessage && this.licenseErrorMessage.length > 0;
    },
    hasCloseAction() {
      const requiresPayment = this.hasPremiumIcons && !this.isPremium;
      return (
        !requiresPayment || this.currentSideBarState === 'showing-license-check'
      );
    },
  },
  watch: {
    hasPremiumIcons: 'iconSelectChangeAction',
  },
  beforeMount() {},

  created() {
    const hash = localStorage.getItem('____hash____icon');
    if (hash === 'auth-special') {
      this.isPremium = true;
    }
  },
  mounted() {
    document
      .querySelector('.main')
      .addEventListener('scroll', this.stickySearch);
    // get theme settings
    this.storedTheme();
    // set the color and size from cookie storage
    this.storedSettings();
    this.iconSelectChangeAction();
  },
  beforeDestroy() {
    document
      .querySelector('.main')
      .addEventListener('scroll', this.stickySearch);
  },
  methods: {
    storedTheme() {
      // get the theme in cookies
      const currTheme = localStorage.getItem('theme');
      if (currTheme === 'dark') {
        // ...let's toggle the .dark-theme class on the body
        document.documentElement.classList.toggle('dark__theme');
      }
      // Otherwise, if the user's preference in localStorage is light...
      else if (currTheme === 'light') {
        // ...let's toggle the .light-theme class on the body
        document.documentElement.classList.toggle('light__theme');
      }
    },
    storedSettings() {
      // get stored items
      const iconSize = localStorage.getItem('iconSize');
      const strokeWidth = localStorage.getItem('strokeWidth');
      const strokeColor = localStorage.getItem('strokeColor');
      const lineJoin = localStorage.getItem('lineJoin');
      const lineCap = localStorage.getItem('lineCap');

      if (iconSize) this.iconSize = iconSize;
      if (strokeWidth) this.strokeWidth = strokeWidth;
      if (strokeColor) this.strokeColor = strokeColor;
      if (lineJoin) this.lineJoin = lineJoin;
      if (lineCap) this.lineCap = lineCap;

      // resize layout
      this.increaseGrid(this.iconSize);
      // update the vue picker with
      document.documentElement.style.setProperty(
        '--selColor',
        this.strokeColor
      );
      this.color = this.strokeColor;
      this.colorValue = this.strokeColor.slice(1);
    },
    async handleIconDeselect(icon, returnIcons = false) {
      this.icons = this.icons.filter(
        (selectedIcon) =>
          !(selectedIcon.name === icon.name && selectedIcon.type === icon.type)
      );

      return returnIcons ? this.icons : null;
    },
    async handleIconSelect(icon) {
      // console.log("select", icon);
      this.icons.push(icon);
    },
    async downloadIcons() {
      if (!this.icons.length) return;
      const { icons } = this;
      const currentTab = this.selected;
      const currentSearchedValue = this.searchedValue;

      this.isDownloading = true;
      this.searchedValue = '';

      if (icons.length === 1) {
        const icon = icons[0];
        if (!this.isActiveTab(icon.type)) {
          this.showTab(icon.type);
          await this.$nextTick();
          await new Promise((resolve) => {
            setTimeout(() => resolve(true), 1000);
          });
        }

        const svgEl = document.querySelector(
          `[data-attr=${icon.name}] > svg > g > path`
        );
        svgEl.setAttribute('stroke', `${this.strokeColor}`);
        const svgData = svgEl.parentNode.parentNode.outerHTML;
        const preface = '<?xml version="1.0" standalone="no"?>\r\n';
        const svgBlob = new Blob([preface, svgData], {
          type: 'image/svg+xml;charset=utf-8',
        });
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = `${icon.name}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        const zip = new JSZip();
        await forAwaitEach(this.tabs, async (tab) => {
          const tabString = tab.toLowerCase();
          const iconsInTab = icons.filter((icon) => tabString === icon.type);

          await this.$nextTick();

          if (!this.isActiveTab(tabString)) {
            this.showTab(tab);
            await this.$nextTick();
            await new Promise((resolve) => {
              setTimeout(() => resolve(true), 1000);
            });
          }

          await forAwaitEach(iconsInTab, async (icon) => {
            const svgEl = document.querySelector(
              `[data-attr=${icon.name}] > svg > g > path`
            );
            await svgEl.setAttribute(
              icon.type === 'outline' ? 'stroke' : 'fill',
              `${this.strokeColor}`
            );
            const svgData = svgEl.parentNode.parentNode.outerHTML;
            await zip.file(`${tabString}/${icon.name}.svg`, `${svgData}`);
          });
        });

        await zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, 'vzy-icons.zip');
        });
      }

      await this.$nextTick();
      if (!this.isActiveTab(currentTab)) {
        this.showTab(currentTab);
      }

      this.searchedValue = currentSearchedValue;
      this.isDownloading = false;
      this.customDownloadText = `${this.icons.length} Icon${
        this.icons.length > 1 ? 's' : ''
      } Downloaded`;
      setTimeout(() => {
        this.customDownloadText = '';
      }, 3000);
    },
    curveIcon() {
      this.lineJoin = 'round';
      this.lineCap = 'round';
      localStorage.setItem('lineJoin', this.lineJoin);
      localStorage.setItem('lineCap', this.lineCap);
      if (this.isActiveTab('solid')) {
        this.$refs.Solid.filterCurveIcon();
      }
    },
    sharpIcon() {
      this.lineJoin = 'arcs';
      this.lineCap = 'square';
      localStorage.setItem('lineJoin', this.lineJoin);
      localStorage.setItem('lineCap', this.lineCap);
      if (this.isActiveTab('solid')) {
        this.$refs.Solid.filterSharpIcon();
      }
    },
    toggleTheme() {
      const darkScheme = window.matchMedia('(prefers-color-scheme: dark)');
      let theme;
      // if the user's os setting is dark and matches .dark__theme
      if (darkScheme.matches) {
        document.documentElement.classList.toggle('light__theme');
        theme = document.documentElement.classList.contains('light__theme')
          ? 'light'
          : 'dark';
      } else {
        // if the user's setting is light mode and matches .light__theme
        document.documentElement.classList.toggle('dark__theme');
        theme = document.documentElement.classList.contains('dark__theme')
          ? 'dark'
          : 'light';
      }
      // Finally, let's save the current preference to localStorage to keep using it
      localStorage.setItem('theme', theme);
    },
    convertToHex(r, g, b, a) {
      let red = r.toString(16);
      let green = g.toString(16);
      let blue = b.toString(16);
      let alpha = Math.round(a * 255).toString(16);

      if (red.length === 1) red = `0${red}`;
      if (green.length === 1) green = `0${green}`;
      if (blue.length === 1) blue = `0${blue}`;
      if (alpha.length === 1) alpha = `0${alpha}`;
      // return
      return `#${red}${green}${blue}${alpha}`;
    },
    colorChange() {
      const temp = document.querySelector('.color__icon').value;
      const curCOLOR = `#${temp}`;
      // getComputedStyle(element).getPropertyValue("--my-var");
      document.documentElement.style.setProperty('--selColor', curCOLOR);
      this.strokeColor = curCOLOR;
      // update the vue picker color
      this.color = curCOLOR;
      // update the input value
      this.colorValue = curCOLOR.slice(1);
      // save to cookie
      localStorage.setItem('strokeColor', this.strokeColor);
    },
    updateValue(tempColor) {
      // if the rgba has an alpha of 1 use the hex value
      if (tempColor.rgba.a === 1) {
        // update the variable --selColor
        document.documentElement.style.setProperty('--selColor', tempColor.hex);
        // update stroke color
        this.strokeColor = tempColor.hex;
        // update the vue picker color
        this.color = tempColor.hex;
        // update the input value
        this.colorValue = tempColor.hex.slice(1);
        // save to cookie
        localStorage.setItem('strokeColor', this.strokeColor);
      } else {
        // get the rgba individual values
        const { r } = tempColor.rgba;
        const { g } = tempColor.rgba;
        const { b } = tempColor.rgba;
        const { a } = tempColor.rgba;

        const rgHex = this.convertToHex(r, g, b, a);
        // update the variable --selColor
        document.documentElement.style.setProperty('--selColor', rgHex);
        // update stroke color
        this.strokeColor = rgHex;
        this.color = rgHex;
        // update the input value
        this.colorValue = rgHex.slice(1);
      }
      // if(tempColor.slice(0, 1) == '#') {
      //     console.log("This is a hex value")
      // }
      // get the value and change it to hex
    },
    viewPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
    },
    showPicker() {
      if (this.displayPicker) this.hidePicker();
      else this.viewPicker();
    },
    showPro() {
      this.licenseErrorMessage = '';
      this.currentSideBarState = 'showing-pro';
      const timeline = gsap.timeline({
        defaults: {
          duration: 0.2,
        },
      });
      timeline
        .to('.license__input-wrap', {
          x: '120%',
        })
        .to('.control', {
          opacity: 0,
        })
        .to('.license-check', {
          display: 'none',
        })
        .to('.customise', {
          display: 'none',
        })
        .to('.checkout', {
          display: 'flex',
        })
        .to('.pro', {
          x: '0px',
        });
    },
    showLicenseCheck() {
      this.licenseErrorMessage = '';
      this.currentSideBarState = 'showing-license-check';
      const timeline = gsap.timeline({
        defaults: {
          duration: 0.2,
        },
      });
      timeline
        .to('.pro', {
          x: '120%',
        })
        .to('.control', {
          opacity: 0,
        })
        .to('.customise', {
          display: 'none',
        })
        .to('.checkout', {
          display: 'none',
        })
        .to('.license-check', {
          display: 'flex',
        })
        .to('.license__input-wrap', {
          x: '0px',
        });
    },
    showEditor() {
      this.licenseErrorMessage = '';
      this.currentSideBarState = 'showing-editor';
      const timeline = gsap.timeline({
        defaults: {
          duration: 0.2,
        },
      });
      timeline
        .to('.license__input-wrap', {
          x: '120%',
        })
        .to('.pro', {
          x: '120%',
        })
        .to('.checkout', {
          display: 'none',
        })
        .to('.license-check', {
          display: 'none',
        })
        .to('.customise', {
          display: 'flex',
        })
        .to('.control', {
          opacity: 1,
        });
    },
    handleCloseAction() {
      const requiresPayment = this.hasPremiumIcons && !this.isPremium;
      const showingLicenseCheck =
        this.currentSideBarState === 'showing-license-check';
      if (!requiresPayment || (requiresPayment && !showingLicenseCheck))
        return this.showEditor();
      if (showingLicenseCheck) return this.showPro();
      return null;
    },
    iconSelectChangeAction() {
      if (this.hasPremiumIcons && !this.isPremium) {
        if (this.currentSideBarState === 'showing-editor') {
          return this.showPro();
        }

        return null;
      }

      return this.showEditor();
    },
    hideCookie() {
      const timeline = gsap.timeline({
        defaults: {
          duration: 0.3,
          delay: 0.2,
        },
      });
      timeline.to('.fixed', {
        display: 'none',
      });
      Cookies.set('show-cookies-msg', true);
    },
    increaseGrid(gridVal) {
      switch (gridVal) {
        case '16':
          document.documentElement.style.setProperty('--col', 16);
          break;
        case '24':
          document.documentElement.style.setProperty('--col', 12);
          break;
        case '32':
          document.documentElement.style.setProperty('--col', 12);
          break;
        case '40':
          document.documentElement.style.setProperty('--col', 10);
          break;
        case '48':
          document.documentElement.style.setProperty('--col', 10);
          break;
        case '56':
          document.documentElement.style.setProperty('--col', 8);
          break;
        case '64':
          document.documentElement.style.setProperty('--col', 8);
          break;
        default:
      }
    },
    changeIconSize(sizeVal) {
      // decrease the value by 8
      const sizeInput = document.querySelector("input[name='iconSize']");
      if (sizeVal === 'decrease') sizeInput.stepDown();
      else sizeInput.stepUp();
      this.iconSize = sizeInput.value;
      this.increaseGrid(this.iconSize);
      // save size to cookie
      localStorage.setItem('iconSize', this.iconSize);
    },
    changeIconWidth(widthVal) {
      const widthInput = document.querySelector("input[name='strokeWidth']");
      if (widthVal === 'decrease') widthInput.stepDown();
      else widthInput.stepUp();
      this.strokeWidth = widthInput.value;
      // save width to cookie
      localStorage.setItem('strokeWidth', this.strokeWidth);
    },
    showTab(tab) {
      this.selected = tab;
    },
    stickySearch() {
      const targ = document.querySelector('.middle');
      const targIcon = document.querySelector('.icons');
      const curtIcon = targIcon.getBoundingClientRect();
      const curt = targ.getBoundingClientRect();
      if (curt.top <= 80 && curtIcon.top <= 179) {
        targ.classList.add('sticky');
      } else {
        targ.classList.remove('sticky');
      }
    },
    documentClick(e) {
      // close the color picker if it is not triggered by the button
      const el = this.$refs.colorpicker;
      const { target } = e;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
    removeError() {
      document.querySelector('.btn-license').removeAttribute('disabled');
      this.licenseErrorMessage = ' ';
      this.butState = 'Activate';
    },
    checkLicenseServer(permalink, license) {
      const host = 'https://server.vzy.co';

      return axios({
        url: `${host}/api/v1/icon_license_validity`,
        method: 'get',
        params: {
          permalink,
          license,
        },
        withCredentials: false,
        validateStatus(status) {
          return status >= 200 && status < 500;
        },
      });
    },
    async checkGumRoadLicense(e) {
      const token = this.licenseKey;
      const permalink = 'vzy-icons-pro';
      const preorders = [
        [
          99,
          111,
          103,
          101,
          110,
          116,
          103,
          101,
          110,
          101,
          43,
          115,
          119,
          105,
          112,
          101,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          100,
          97,
          110,
          102,
          114,
          97,
          110,
          118,
          102,
          120,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          111,
          110,
          115,
          105,
          108,
          97,
          115,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          99,
          111,
          110,
          110,
          111,
          114,
          46,
          118,
          97,
          110,
          104,
          101,
          101,
          114,
          116,
          117,
          109,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          105,
          116,
          122,
          107,
          119,
          97,
          121,
          115,
          105,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          100,
          104,
          97,
          114,
          109,
          101,
          122,
          105,
          49,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          99,
          104,
          105,
          110,
          121,
          101,
          114,
          101,
          109,
          117,
          103,
          111,
          107,
          119,
          101,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          111,
          107,
          97,
          102,
          111,
          114,
          46,
          101,
          114,
          105,
          99,
          97,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
        [
          115,
          101,
          108,
          111,
          114,
          109,
          107,
          119,
          97,
          115,
          105,
          64,
          103,
          109,
          97,
          105,
          108,
          46,
          99,
          111,
          109,
        ],
      ];

      let isTokenInPreorders = false;
      for (let i = 0; i < preorders.length; i += 1) {
        const encodedString = preorders[i];
        let decodedString = '';

        encodedString.forEach((encodedChar) => {
          decodedString += String.fromCharCode(encodedChar);
        });

        if (String(token).trim() === decodedString) {
          isTokenInPreorders = true;
          break;
        }
      }

      try {
        if (!isTokenInPreorders) {
          const response = await this.checkLicenseServer(permalink, token);
          if (response.status !== 200) {
            this.butState = 'Invalid License';
            // disable button
            e.target.disabled = true;
            // add error to input
            document
              .querySelector('license__input input')
              .classList.add('error');
            return;
          }
        }

        // this.licenseErrorMessage = '';
        this.butState = 'Activate';
        localStorage.setItem('____hash____icon', 'auth-special');
        this.isPremium = true;
        this.showEditor();
      } catch (error) {
        this.licenseErrorMessage = 'Ooops! try again';
      }
    },
    clearAllSelectedIcons() {},
    handlePay() {
      window.open('https://gumroad.com/l/vzy-icons-pro', '_blank');
    },
  },
};
</script>
